<mat-form-field class="abcdj-mat-form-field" appearance="fill" floatLabel="always">
  <mat-label>{{label}}</mat-label>
  <input type="text" matInput [formControl]="selected" [placeholder]="placeholder" [required]="required"
    [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <ng-container *ngIf="options$ | async as options; else loadingTpl">
      <mat-option *ngFor="let option of options.content" [value]="option">
        {{ option.label }}
      </mat-option>
      <mat-option *ngIf="!options.isLast" disabled>Other options available, please refine the query to see more...</mat-option>
    </ng-container>
    <ng-template #loadingTpl>
      <mat-option disabled>Loading...</mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
