<div #wrapper class="wrapper" (hdisResize)="onResize($event)">
  <mat-slider step="0.05" min="0" max="1" discrete>
    <input matSliderThumb [(ngModel)]="percentThreshold">
  </mat-slider>
  <button mat-icon-button color="accent" (click)="onFilter($event)">
    <mat-icon>search</mat-icon>
  </button>

  <span *ngIf="loading">Loading...</span>
  <span *ngIf="error">Whoops something went wrong</span>
  <svg #chart class="chart"></svg>
</div>
