import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOULD_UNAUTHORIZED_LOGOUT } from '@heardis/hdis-ui';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../_services/auth.service';

/**
 * This is used to logout the user, when the server responds with an unathorized status code.
 * Especially when the session token expires.
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
  ) { }

  /**
   * Intercepter intercepts the responses, and then process based on the recieved status code
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof ErrorInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        /**
         * skip custom error handling in the following cases:
         * - not originated by the abcdj backend (i.e. fetching resources from 3rd parties)
         * - not an authentication error
         */

        if (request.url.startsWith(environment.apiBaseUrl) && err.status === 401 && request.context.get(SHOULD_UNAUTHORIZED_LOGOUT) === true) {
          // auto logout if 401 response returned from api
          this.authService.logout();
        } else {
          return throwError(() => err);
        }

        // // err.error is not null, if the ResponsenEntity contains an Exception
        // // err.error.message will give the custom message send from the server
        // const error = err.error.message || err.statusText;
        // return throwError(error);
      }),
    );
  }
}
