import { inject, Injectable } from '@angular/core';
import { Playlist, RequestRange, RequestSort, Resource, Schedule, ScheduleApproveResponse, ScheduleArchiveResponse, ScheduleDiscardResponse, ScheduleFile, ScheduleFileStage, ScheduleImportFromPlaylistRequest, ScheduleImportFromPlaylistResponse, ScheduleValidateResponse, SearchResponseBody } from '@heardis/api-contracts';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EntityService } from './entity.service';
import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class ScheduleService extends EntityService<Schedule> {
  baseUrl = `${environment.apiBaseUrl}${environment.endpoints.schedules}`;

  resource = Resource.SCHEDULE;

  entityId = 'schedule';

  prService = inject(ProfileService);

  getEntityRoute(entityId: string): string[] {
    return ['/scheduling/schedules', entityId];
  }

  getSchedulePlaylists(scheduleId: string, range?: RequestRange, sort?: RequestSort[]): Observable<SearchResponseBody<Playlist>> {
    const url = `${this.baseUrl}/${scheduleId}/_playlists`;
    const queryParams = this.parseQueryParams(range, sort);
    return this.http.get<Playlist[]>(url, { params: queryParams, observe: 'response' })
      .pipe(map(this.handleResponseList), take(1));
  }

  getTableColumns() {
    return super.getTableColumns().pipe(
      map((columns) => columns.map((colDef) => {
        if (colDef.field === 'profileId') {
          return {
            ...colDef,
            type: ['entityColumn'],
            cellRendererParams: {
              fetchFn: this.getProfileLabel,
            },
          };
        } if (colDef.field === 'startDate' || colDef.field === 'endDate') {
          return {
            ...colDef,
            filter: false,
          };
        }
        return colDef;
      })),
    );
  }

  getProfileLabel = (profileId: string): Observable<string> => this.prService.getEntityLabel(profileId);

  getFiles = (scheduleId: string, type: ScheduleFileStage) => this.http.get<ScheduleFile[]>(`${this.baseUrl}/${scheduleId}/files/${type}`).pipe(take(1));

  approvePendingFiles = (scheduleId: string, fileIds: string[]) => this.http.post<ScheduleApproveResponse>(`${this.baseUrl}/${scheduleId}/files/_approve`, fileIds).pipe(take(1));

  discardPendingFiles = (scheduleId: string, fileIds: string[]) => this.http.post<ScheduleDiscardResponse>(`${this.baseUrl}/${scheduleId}/files/_discard`, fileIds).pipe(take(1));

  validatePendingFiles = (scheduleId: string, fileIds: string[]) => this.http.post<ScheduleValidateResponse>(`${this.baseUrl}/${scheduleId}/files/_validate`, fileIds).pipe(take(1));

  archiveOldFiles = (scheduleId: string, fileIds: string[]) => this.http.post<ScheduleArchiveResponse>(`${this.baseUrl}/${scheduleId}/files/_archive`, fileIds).pipe(take(1));

  importFromPlaylist = (scheduleId: string, payload: ScheduleImportFromPlaylistRequest) => this.http.post<ScheduleImportFromPlaylistResponse>(`${this.baseUrl}/${scheduleId}/files/_import`, payload).pipe(take(1));
}

/**
 * Parse string date to Date object ignoring timezone if defined.
 * This is needed for dates defined in schedule timeslots to make them
 * consistent regardless of the timezone of the user browser.
 *
 * This is extra car because dates should already come without Z from the backend
 * But you never know, right?
 * @param strDate RFC-3339 string representation of dates. e.g. 1970-01-01T00:00:00.000Z
 */
// in 1970-01-01T00:00:00.000Z "Z" is the 24th char and describes UTC
const parseDate = (strDate: string): Date => (strDate ? new Date(strDate?.slice(0, 23)) : null);
