import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Pool, Profile, ProfileActionRequest, ProfileTrack, ProfileTracksRequestBody, Query, RequestProps, RequestRange, RequestSort, Resource, SearchResponseBody, TrackExportRequest } from '@heardis/api-contracts';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AudioPlayerTrack } from '../audio-player';
import { EntityService } from './entity.service';
import { SongService } from './song.service';

@Injectable({ providedIn: 'root' })
export class ProfileService extends EntityService<Profile> {
  baseUrl = environment.apiBaseUrl + environment.endpoints.profiles;

  resource = Resource.PROFILE;

  entityId = 'profile';

  sService = inject(SongService);

  getEntityRoute(entityId: string): string[] {
    return ['/customers/profiles', entityId];
  }

  getProfileTracks(profileId: string, overwrite: Query, filter: Query, range?: RequestRange, sort?: RequestSort[], props?: RequestProps): Observable<SearchResponseBody<ProfileTrack>> {
    const requestBody: ProfileTracksRequestBody = {
      ...(filter ? { filter } : {}),
      ...(overwrite ? { definition: overwrite } : {}),
      ...(props ? { ...props } : {}),
    };
    return this.searchListInternal<ProfileTrack>(`${this.baseUrl}/${profileId}/_songs`, requestBody, range, sort);
  }

  getProfilePlayerTracks(profileId: string, range?: RequestRange, sort?: RequestSort[]): Observable<AudioPlayerTrack[]> {
    const requestBody: ProfileTracksRequestBody = {
      projection: 'player',
    };
    return this.searchListInternal<ProfileTrack>(`${this.baseUrl}/${profileId}/_songs`, requestBody, range, sort).pipe(
      map((response) => response.content),
      map((tracks) => tracks.map(this.sService.mapToAudioPlayerTrack)),
    );
  }

  getProfilePools(profileId: string, range?: RequestRange, sort?: RequestSort[]): Observable<SearchResponseBody<Pool>> {
    // TODO remove draft from allowed statuses
    const queryParams = this.parseQueryParams(range, sort);
    return this.http
      .get<Pool[]>(`${this.baseUrl}/${profileId}/pools`, { params: queryParams, observe: 'response' })
      .pipe(map((response) => this.handleResponseList<Pool>(response)), take(1));
  }

  getProfileFacets(profileId: string): Observable<Record<string, Query>> {
    return this.http
      .get<Record<string, Query>>(`${this.baseUrl}/${profileId}/_facets`)
      .pipe(take(1));
  }

  addPools(profileId: string, poolIds: string[]): Observable<Profile> {
    return this.http.post<Profile>(`${this.baseUrl}/${profileId}/pools`, poolIds)
      .pipe(map(this.handleResponse), take(1));
  }

  removePools(profileId: string, poolIds: string[]): Observable<Profile> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: poolIds,
    };
    return this.http.delete<Profile>(`${this.baseUrl}/${profileId}/pools`, options)
      .pipe(map(this.handleResponse), take(1));
  }

  getTrackList(profileId: string, list: 'whitelist' | 'blacklist', filter: Query, range?: RequestRange, sort?: RequestSort[]): Observable<SearchResponseBody<ProfileTrack>> {
    // TODO remove draft from allowed statuses
    const queryParams = this.parseQueryParams(range, sort);
    return this.http
      .get<ProfileTrack[]>(`${this.baseUrl}/${profileId}/${list}`, { params: queryParams, observe: 'response' })
      .pipe(map((response) => this.handleResponseList<ProfileTrack>(response)), take(1));
  }

  addToList(profileId: string, list: 'whitelist' | 'blacklist', trackIds: string[]): Observable<Profile> {
    return this.http.post<Profile>(`${this.baseUrl}/${profileId}/${list}`, trackIds)
      .pipe(map(this.handleResponse), take(1));
  }

  removeFromList(profileId: string, list: 'whitelist' | 'blacklist', trackIds: string[]): Observable<Profile> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: trackIds,
    };
    return this.http.delete<Profile>(`${this.baseUrl}/${profileId}/${list}`, options)
      .pipe(map(this.handleResponse), take(1));
  }

  getTermFilter(term: string): Query {
    return { or: [{ regex: { name: term.toLowerCase() } }] } as Query;
  }

  getTableColumns() {
    return super.getTableColumns();
  }

  requestAction(docId: string, payload: ProfileActionRequest): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${docId}/_action`, payload).pipe(take(1));
  }

  requestTracksExportAction(docId: string, payload: TrackExportRequest): Observable<string> {
    return this.http.post<{ url: string }>(`${this.baseUrl}/${docId}/_action`, payload).pipe(
      map((data) => data.url),
      take(1),
    );
  }
}
