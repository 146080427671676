import { ColDef } from '@ag-grid-community/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntityPermissionOp, Field, FieldType, HttpError, RequestProps, RequestRange, RequestSort, Resource, Revision, Scope, SearchRequestBody, SearchResponseBody, VersionableEntity, VersionableEntityStatus } from '@heardis/api-contracts';
import { EntityProvider, FieldCondition, FieldValue, Icons, LocalizedFieldCondition, mapFieldsToColumnDefinitions, MessageSeverity, MetadataProvider } from '@heardis/hdis-ui';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Page } from '../_models/request';
import { getUserLanguage } from '../_state/view-preferences/view-preferences.selectors';
import { AuthService } from './auth.service';

export abstract class EntityService<Entity extends VersionableEntity> extends MetadataProvider implements EntityProvider<Entity> {
  i18nContext: string;

  entityId = 'resource';

  localizedFields = [];

  dateFields = ['createdAt', 'updatedAt'];

  queryFields: Observable<Field[]>;

  fields: Observable<Field[]>;

  fieldValues: { [field: string]: Observable<FieldValue[]> } = {};

  entityLabels = new Map<string, Observable<string>>();

  abstract baseUrl: string;

  abstract resource: Resource;

  protected http = inject(HttpClient);

  protected snackbar = inject(MatSnackBar);

  protected i18n = inject(TranslocoService);

  protected authService = inject(AuthService);

  protected store = inject(Store);

  searchList<T = Entity>(filter?: any, range?: RequestRange, sort?: RequestSort[], props?: RequestProps, jsonl = false): Observable<SearchResponseBody<T>> {
    const requestBody: SearchRequestBody = {
      ...(filter ? { filter } : {}),
      ...(props ? { ...props } : {}),
    };
    return this.searchListInternal(`${this.baseUrl}/_search`, requestBody, range, sort, props, jsonl);
  }

  searchEntities(filter: any, range?: RequestRange, sort?: RequestSort[]): Observable<SearchResponseBody<Entity>> {
    return this.searchList(filter, range, sort);
  }

  autocomplete(fields, page?: Page): Observable<SearchResponseBody<Entity>> {
    const queryParams = Object.entries(fields).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    return this.http.get(`${this.baseUrl}/autocomplete`, { params: queryParams })
      .pipe(map(this.handleResponseList), take(1));
  }

  getEntity(entityId: string, params?: { [s: string]: string }): Observable<Entity> {
    const queryParams = (params) ? new HttpParams({ fromObject: params }) : {};

    return this.http.get(`${this.baseUrl}/${entityId}`, { params: queryParams })
      .pipe(map(this.handleResponse), take(1));
  }

  abstract getEntityRoute(entityId: string): string[];

  postEntity(payload: Partial<Entity>): Observable<Entity> {
    return this.http.post(this.baseUrl, this.handleRequestPayload(payload))
      .pipe(map(this.handleResponse), take(1));
  }

  updateEntity(entityId: string, payload: Entity): Observable<Entity> {
    if (this.entityLabels.has(entityId)) this.entityLabels.delete(entityId);
    return this.http.put(`${this.baseUrl}/${entityId}`, this.handleRequestPayload(payload))
      .pipe(map(this.handleResponse), take(1));
  }

  partialUpdateEntity(entityId: string, revId: number, payload: Partial<Entity>): Observable<Entity> {
    const headers = new HttpHeaders({
      'If-Match': String(revId),
    });

    if (this.entityLabels.has(entityId)) this.entityLabels.delete(entityId);
    return this.http.patch(`${this.baseUrl}/${entityId}`, this.handleRequestPayload(payload), { headers })
      .pipe(
        map(this.handleResponse),
        catchError((err: HttpErrorResponse) => {
          if (err.error?.code === HttpError.RESOURCE_LOCKED) {
            this.snackbar.open(`The ${this.entityId} was changed on the server while editing, please reload the page to get the latest changes`, '', { panelClass: [MessageSeverity.ERROR] });
          } else {
            this.snackbar.open(`failed to save the changes: ${err.error.message}`, '', { panelClass: [MessageSeverity.ERROR] });
          }
          // forward error to the subscriber
          return throwError(err);
        }),
        take(1),
      );
  }

  changeEntityStatus(entityId: string, revId: number, status: string): Observable<Entity> {
    const headers = new HttpHeaders({
      'If-Match': String(revId),
    });

    if (this.entityLabels.has(entityId)) this.entityLabels.delete(entityId);
    return this.http.patch(`${this.baseUrl}/${entityId}/_status`, { status }, { headers })
      .pipe(
        map(this.handleResponse),
        catchError((err: HttpErrorResponse) => {
          if (err.error?.code === HttpError.RESOURCE_LOCKED) {
            this.snackbar.open(`The ${this.entityId} was changed on the server while editing, please reload the page to get the latest changes`, '', { panelClass: [MessageSeverity.ERROR] });
          } else {
            this.snackbar.open(`failed to change status: ${err.error.message}`, '', { panelClass: [MessageSeverity.ERROR] });
          }
          // forward error to the subscriber
          return throwError(err);
        }),
        take(1),
      );
  }

  deleteEntity(entityId: string, permanent = false): Observable<Entity> {
    let params = new HttpParams();
    if (permanent) {
      params = params.set('permanent', String(true));
    }
    if (this.entityLabels.has(entityId)) this.entityLabels.delete(entityId);
    return this.http.delete(`${this.baseUrl}/${entityId}`, { params })
      .pipe(map(this.handleResponse), take(1));
  }

  restoreEntity(entityId: string): Observable<Entity> {
    if (this.entityLabels.has(entityId)) this.entityLabels.delete(entityId);
    return this.http.patch(`${this.baseUrl}/${entityId}/_restore`, null)
      .pipe(map(this.handleResponse), take(1));
  }

  cloneEntity(entityId: string, data?: Partial<Entity>): Observable<Entity> {
    return this.http.post(`${this.baseUrl}/${entityId}/_clone`, data)
      .pipe(map(this.handleResponse), take(1));
  }

  parseQueryParams(range?: RequestRange, sort?: RequestSort[], props?: RequestProps): HttpParams {
    let params = new HttpParams();
    if (range?.offset) params = params.set('offset', String(range.offset));
    if (range?.limit) params = params.set('limit', String(range.limit));

    sort?.forEach((column) => {
      params = params.append('order', `${column.field}:${column.order}`);
    });

    if (props?.projection) {
      params = params.set('projection', props.projection);
    } else if (props?.fields) {
      props.fields.forEach((fieldName) => { params = params.append('fields', fieldName); });
    }

    return params;
  }

  handleRequestPayload(req: Partial<Entity>): any {
    return req;
  }

  handleResponse<T = Entity>(res): T {
    if (res?.createdAt) res.createdAt = new Date(res.createdAt);
    if (res?.updatedAt) res.updatedAt = new Date(res.updatedAt);
    return <T>res;
  }

  handleResponseList = <T = Entity>(res: HttpResponse<T[]>): SearchResponseBody<T> => {
    const retValue: SearchResponseBody<T> = {
      totalElements: parseInt(res.headers.get('hdis-total'), 10),
      isFirst: String(res.headers.get('hdis-first')).toLowerCase() === 'true',
      isLast: String(res.headers.get('hdis-last')).toLowerCase() === 'true', // used to do !!res.headers.get('hdis-last'), but string 'false' would evalute true
      content: res.body.map((rawResponse) => this.handleResponse(rawResponse)),
    };
    return retValue;
  };

  handleJSONLResponse = <T = Entity>(res: HttpResponse<string>): SearchResponseBody<T> => {
    const retValue: SearchResponseBody<T> = {
      totalElements: parseInt(res.headers.get('hdis-total'), 10),
      isFirst: String(res.headers.get('hdis-first')).toLowerCase() === 'true',
      isLast: String(res.headers.get('hdis-last')).toLowerCase() === 'true', // used to do !!res.headers.get('hdis-last'), but string 'false' would evalute true
      content: res.body.split('\n').filter((row) => !!row).map((row) => JSON.parse(row) as T),
    };
    return retValue;
  };

  /**
   * Performs a POST request to the given URL which is expected to be
   * a search-endpoint.
   * @param endpoint
   * @param requestBody
   * @param range
   * @param sort
   * @param status
   */
  protected searchListInternal<T = Entity>(
    endpoint: string,
    requestBody: SearchRequestBody,
    range?: RequestRange,
    sort?: RequestSort[],
    props?: RequestProps,
    jsonl = false,
  ): Observable<SearchResponseBody<T>> {
    const queryParams = this.parseQueryParams(range, sort, props);

    if (jsonl) return this.http.post(endpoint, requestBody, { params: queryParams, responseType: 'text', observe: 'response' }).pipe(map((response) => this.handleJSONLResponse<T>(response)), take(1));

    return this.http.post<T[]>(endpoint, requestBody, { params: queryParams, observe: 'response' }).pipe(map((response) => this.handleResponseList(response)), take(1));
  }

  /**
   * Get the available fields for the entity.
   * Note that this is only evaluated once against the server until the application gets refreshed.
   * In case of an error while fetching entity-fields, results will not be cached.
   */
  getEntityFields(): Observable<Field[]> {
    if (!this.fields) {
      this.fields = this.http.get<Field[]>(`${this.baseUrl}${environment.endpoints.fields}`, { params: { ctx: 'edit' } }).pipe(
        shareReplay(),
      );
    }
    return this.fields;
  }

  /**
   * Get the available published fields for the entity.
   * Note that this is only evaluated once against the server until the application gets refreshed.
   * In case of an error while fetching entity-fields, results will not be cached.
   */
  getQueryFields(): Observable<Field[]> {
    if (!this.queryFields) {
      this.queryFields = this.http.get<Field[]>(`${this.baseUrl}${environment.endpoints.fields}`, { params: { ctx: 'query' } }).pipe(
        shareReplay(),
      );
    }
    return this.queryFields;
  }

  /**
   * Get the available fields for the entity.
   */
  getFieldValues(fieldName: string, keyword?: string, withInvalid?: boolean): Observable<FieldValue[]> {
    if (!fieldName) return of([]);
    let cacheKey; let
      params;
    cacheKey = fieldName;
    params = new HttpParams({ fromObject: { field: fieldName } });
    if (keyword) {
      cacheKey = `${cacheKey}-${keyword}`;
      params = params.set('filter', keyword);
    }
    if (withInvalid) {
      cacheKey = `${cacheKey}-withInvalid`;
      params = params.set('withInvalid', true);
    }
    if (!this.fieldValues[cacheKey]) {
      this.fieldValues[cacheKey] = this.http.get<FieldValue[]>(`${this.baseUrl}${environment.endpoints.fieldValues}`, { params }).pipe(
        map((fieldValues) => {
          const localizable = this.isFieldLocalized(fieldName);
          console.debug(`fetch values for field ${fieldName}: ${localizable ? 'localize' : 'do not localize'}`);
          return fieldValues.map((fieldValue) => ({
            value: fieldValue,
            label: localizable ? this.i18n.translate(`entities.${this.entityId}.${fieldName}.values.${fieldValue}`) : String(fieldValue),
          }));
        }),
        shareReplay(),
      );
    }
    return this.fieldValues[cacheKey];
  }

  isFieldLocalized(fieldName: string) {
    return this.localizedFields.includes(fieldName);
  }

  /**
   * Get the list of conditions supported for queries on the given field
   * @param fieldName
   */
  getQueryConditions(): Observable<LocalizedFieldCondition[]> {
    // currently hardcoded, should come from backend
    const fieldConditions: FieldCondition[] = [
      { id: 'contains', type: 'single', criteria: [{ type: FieldType.STRING, isEnum: false }] },
      { id: 'between', type: 'range', criteria: [{ type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'equals', type: 'single', criteria: [{ type: FieldType.STRING, isMulti: false }, { type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'notEquals', type: 'single', criteria: [{ type: FieldType.STRING, isMulti: false }, { type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'startsWith', type: 'single', criteria: [{ type: FieldType.STRING, isEnum: false, isMulti: false }] },
      { id: 'endsWith', type: 'single', criteria: [{ type: FieldType.STRING, isEnum: false, isMulti: false }] },
      { id: 'lt', type: 'single', criteria: [{ type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'gt', type: 'single', criteria: [{ type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'lte', type: 'single', criteria: [{ type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'gte', type: 'single', criteria: [{ type: FieldType.NUMBER, isMulti: false }, { type: FieldType.DATE, isMulti: false }] },
      { id: 'terms', type: 'multi', criteria: [{ isEnum: true }, { type: FieldType.STRING }] },
      { id: 'notTerms', type: 'multi', criteria: [{ isEnum: true }] },
      { id: 'allOf', type: 'multi', criteria: [{ isEnum: true, isMulti: true }] },
      { id: 'noneOf', type: 'multi', criteria: [{ isEnum: true, isMulti: true }] },
      { id: 'exists', type: 'empty', criteria: [{ isMeta: false }] },
      { id: 'notExists', type: 'empty', criteria: [{ isMeta: false }] },
    ];

    return combineLatest([
      // as conditions will be migrated to the backend, they will be handledas observable, so we do it right away
      of(fieldConditions),
      this.store.select(getUserLanguage),
    ]).pipe(map(([conditions, lang]) => conditions.map((condition) => ({
      ...condition,
      label: this.i18n.translate(`query.condition.labels.${condition.id}`),
    })) as LocalizedFieldCondition[]));
  }

  /**
   * @inheritdoc
   */
  getTableColumns(i18nPrefix?: string): Observable<ColDef[]> {
    const translationPrefix = i18nPrefix || `entities.${this.entityId}.`;
    return combineLatest([
      this.getEntityFields(),
      this.store.select(getUserLanguage),
    ]).pipe(
      map(([columns, language]) => mapFieldsToColumnDefinitions(columns)),
      map((columns) => columns
        .map((column) => {
          if (column.field === 'status') {
            return {
              ...column,
              headerValueGetter: () => '',
              type: [...column.type, 'iconColumn'],
              cellRendererParams: {
                icons: {
                  [VersionableEntityStatus.DRAFT]: Icons.STATUS_DRAFT,
                  [VersionableEntityStatus.DELETED]: Icons.STATUS_DELETED,
                  [VersionableEntityStatus.PUBLISHED]: Icons.STATUS_PUBLISHED,
                },
                labels: {
                  [VersionableEntityStatus.DRAFT]: this.i18n.translate('entities.common.status.values.draft'),
                  [VersionableEntityStatus.DELETED]: this.i18n.translate('entities.common.status.values.deleted'),
                  [VersionableEntityStatus.PUBLISHED]: this.i18n.translate('entities.common.status.values.published'),
                },
              },
            };
          }
          return {
            ...column,
            headerName: this.i18n.translate(`${translationPrefix}${column.headerName}`),
          };
        })
        .sort((a, b) => {
          if (a.headerName > b.headerName) return 1;
          if (b.headerName > a.headerName) return -1;
          return 0;
        })),
    );
  }

  getRevisions(entityId: string, range?: RequestRange, sort?: RequestSort[], status?: string[]): Observable<SearchResponseBody<Revision>> {
    return this.http.get<Revision[]>(`${this.baseUrl}/${entityId}/revisions`, { observe: 'response' })
      .pipe(map(this.handleResponseList), take(1));
  }

  getPermissions(entityId: string): Observable<Entity> {
    return this.http.get(`${this.baseUrl}/${entityId}/_permissions`)
      .pipe(map(this.handleResponse), take(1));
  }

  setPermissions(entityId: string, revId: number, ops: EntityPermissionOp[]): Observable<Entity> {
    const headers = new HttpHeaders({
      'If-Match': String(revId),
    });

    return this.http.post(`${this.baseUrl}/${entityId}/_permissions`, ops, { headers })
      .pipe(map(this.handleResponse), take(1));
  }

  getEntityLabel(entityId: string, field = 'name'): Observable<string> {
    const cacheKey = `${entityId}-${field}`;
    if (!this.entityLabels.has(cacheKey)) {
      this.entityLabels.set(cacheKey, this.getEntity(entityId).pipe(
        map((entity) => (entity as any)[field]),
        shareReplay(),
      ));
    }
    return this.entityLabels.get(cacheKey);
  }

  canCreate = (entity?: Entity): boolean => this.authService.hasPermission(this.resource, Scope.CREATE);

  canRead = (entity?: Entity): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.READ)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (
        (this.authService.hasPermission(this.resource, Scope.READ_OWN) && entity.createdBy === username) ||
        entity.acl?.[username]?.some((cap) => cap === Scope.READ)
      ) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.READ_OWN)) {
      return true;
    }

    return false;
  };

  canEdit = (entity?: Entity): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.EDIT)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (
        (this.authService.hasPermission(this.resource, Scope.EDIT_OWN) && entity.createdBy === username) ||
        entity.acl?.[username]?.some((cap) => cap === Scope.EDIT)
      ) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.EDIT_OWN)) {
      return true;
    }

    return false;
  };

  canDelete = (entity?: Entity): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.DELETE)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (
        (this.authService.hasPermission(this.resource, Scope.DELETE_OWN) && entity.createdBy === username) ||
        entity.acl?.[username]?.some((cap) => cap === Scope.DELETE)
      ) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.DELETE_OWN)) {
      return true;
    }

    return false;
  };

  canRestore = (entity?: Entity): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.RESTORE)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (
        (this.authService.hasPermission(this.resource, Scope.RESTORE_OWN) && entity.createdBy === username) ||
        entity.acl?.[username]?.some((cap) => cap === Scope.RESTORE)
      ) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.RESTORE_OWN)) {
      return true;
    }

    return false;
  };

  canManagePermissions = (entity?: Entity): boolean => this.authService.hasPermission(this.resource, Scope.MANAGE_PERMISSIONS);
}
