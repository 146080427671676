import { AgGridModule } from '@ag-grid-community/angular';
import { CellContextMenuEvent, CellPosition, ColDef, Column, ColumnApi, ColumnState, ColumnVisibleEvent, DisplayedColumnsChangedEvent, FilterChangedEvent, GetRowNodeIdFunc, GridApi, GridOptions, GridReadyEvent, NavigateToNextCellParams, RowClickedEvent, RowDoubleClickedEvent, RowEvent, SelectionChangedEvent, SortChangedEvent } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject, fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { UserPreferenceProvider } from '../_models';
import { ChordChartComponent } from '../charts/chord-chart/chord-chart.component';
import { Identifiable } from '../common';
import { BaseComponent } from '../common/base-component/base.component';
import { IllegalStateError } from '../common/errors/errors';
import { ContextMenuDropdownComponent } from '../context-menu';
import { ContextMenuActionEvent, ContextMenuItem } from '../context-menu/context-menu.interfaces';
import columnTypes from './column-types';
import { AbstractDatasource } from './datasource/abstract-datasource';
import { MultivalueFilterComponent } from './filters/multivalue-filter/multivalue-filter.component';
import { ColumnHeaderEvent, TableHeaderMenuComponent } from './menus/header/header-menu.component';
import { EntityCellRendererComponent } from './renderers/entity/entity-renderer.component';
import { IconCellRendererComponent } from './renderers/icon/icon-renderer.component';
import { ThumbnailCellRendererComponent } from './renderers/thumbnail/thumbnail-renderer.component';
import { TrendCellRendererComponent } from './renderers/trend/trend-renderer.component';
import { BoundContextMenuItem, ICON_CELL_WIDTH, PREFIX_INTERNAL_COLUMN, RowModelType, RowSelectionMode, RowSelectionType } from './table.interfaces';

@Component({
  selector: 'hdis-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, FlexModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatMenuModule, AgGridModule, ChordChartComponent, TableHeaderMenuComponent],
})
export class TableComponent<T extends Identifiable> extends BaseComponent implements OnInit, OnChanges {
  ready = false;

  /** Optional title shown on top of the table to indicate what's being displayed. */
  @Input() title?: string;

  /** Optional scope used to identify the context of the table (e.g. store its columns). */
  @Input() scope: string;

  /** Ag-grid compliant list of table columns. Among the others, each item can specify custom label, sorting criteria and visibility. */
  @Input() columns: { definitions: ColDef[]; initialState: ColumnState[]; defaultState: ColumnState[] };

  /** Whether to refresh the rows after the columns changed. Especially useful for infinite rowModelType and dynamic list of fields  */
  @Input('refreshRowsOnColumnChange')
  get refreshRowsOnColumnChange(): boolean { return this._refreshRowsOnColumnChange; }

  set refreshRowsOnColumnChange(value) { this._refreshRowsOnColumnChange = value; }

  _refreshRowsOnColumnChange = false;

  @Input() rowSelectionMode: RowSelectionMode = 'none';

  @Input() rowSelectionType: RowSelectionType = 'checkbox';

  @Input() showQuickFilter?: boolean;

  @Input() quickFilterPlaceholder?: string;

  @Input() showColumnFilter?: boolean;

  @Input() animateRows?: boolean = false;

  @Input() getRowNodeId: GetRowNodeIdFunc = (data: T) => data._id;

  @Input() rowData?: T[];

  /** Optional datasource in case datasourceType is 'infinite' */
  @Input() datasource?: AbstractDatasource<T>;

  @Input() contextMenu: ContextMenuItem<T>[] = [];

  /** Optionally allows component users to pass in an observable through which a table refresh can be triggered. */
  @Input() refresh?: Observable<void>;

  @Input() noRowsMessage = 'No data available';

  @Input() loadingMessage = 'Loading data...';

  /*
   * OUTPUTS
   */
  @Output() readonly columnsChange = new EventEmitter<ColumnState[]>();

  @Output() readonly sortChange = new EventEmitter<ColumnState[]>();

  @Output() readonly selectionChange = new EventEmitter<T[]>();

  @Output() readonly activeRowChange = new EventEmitter<T>();

  @Output() readonly rowClicked = new EventEmitter<RowEvent>();

  @Output() readonly rowDoubleClicked = new EventEmitter<RowEvent>();

  @Output() readonly contextMenuAction = new EventEmitter<ContextMenuActionEvent>();

  @ContentChild(ContextMenuDropdownComponent) rowContextMenu: ContextMenuDropdownComponent<T>;

  @ViewChild('headerContextMenuTrigger', { read: MatMenuTrigger }) headerContextMenuTrigger: MatMenuTrigger;

  headerContextMenuPosition = { x: '0px', y: '0px' };

  /*
   * FIELDS USED FOR TEMPLATE BINDING
   */
  view: 'table' | 'chart' = 'table';

  stats$: Observable<any>;

  headerColumns: Column[] = [];

  gridOptions: GridOptions;

  quickSearchInput: string;

  public quickSearchQuery: string;

  quickSearchUpdate = new Subject<string>();

  rowModelType: RowModelType;

  /*
   * PRIVATE FIELDS
   */
  private gridApi: GridApi;

  private columnApi: ColumnApi;

  constructor(
    private elementRef: ElementRef,
    @Optional() private uService: UserPreferenceProvider,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.rowModelType = this.datasource ? 'infinite' : 'clientSide';

    this.gridOptions = {
      cacheBlockSize: 100,
      suppressDragLeaveHidesColumns: true,
      // suppressRowClickSelection: true,
      // suppressCellFocus: true, // used to be a nice workaround to disable cell selection and still bind on the keyboard keys. However
      rowModelType: this.rowModelType,
      rowSelection: this.rowSelectionMode === 'none' ? null : this.rowSelectionMode,
      context: {
        parent: this,
      },
      animateRows: this.animateRows,
      defaultColDef: {
        floatingFilter: true,
        filter: true,
        suppressMenu: true,
        resizable: true,
        filterParams: {
          buttons: ['clear'],
        },
        hide: true,
      },
      columnTypes,
      components: {
        hdisMultiValueFilter: MultivalueFilterComponent,
        entityCellRenderer: EntityCellRendererComponent,
        iconCellRenderer: IconCellRendererComponent,
        thumbnailCellRenderer: ThumbnailCellRendererComponent,
        trendCellRenderer: TrendCellRendererComponent,
      },

      navigateToNextCell: this.navigateToNextCell,
      getRowNodeId: this.getRowNodeId,

      onGridReady: this.onGridReady,
      onColumnVisible: this.onColumnVisible,
      onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
      onSortChanged: this.onSortChanged,
      onFilterChanged: this.onFilterChanged,
      onSelectionChanged: this.onSelectionChanged,
      onRowClicked: this.onRowClicked,
      onRowDoubleClicked: this.onRowDoubleClicked,
      onCellContextMenu: this.onCellContextMenu,

      overlayNoRowsTemplate: this.noRowsMessage,
      overlayLoadingTemplate: this.loadingMessage,
    };
    if (this.showColumnFilter) {
      // this.gridOptions.defaultColDef?.floatingFilter = true

    }
    this.quickSearchUpdate.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
    )
      .subscribe((value) => {
        this.quickSearchQuery = value;
        this.clearSelection();
        this.getGridApi().purgeInfiniteCache();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rowData && this.ready) {
      this.gridApi?.setRowData(this.rowData as T[]);
    } else if (changes.datasource && this.ready) {
      this.gridApi?.setDatasource(this.datasource as AbstractDatasource<T>);
    }
  }

  refreshResults() {
    this.getGridApi().purgeInfiniteCache();
  }

  /*
   * EVENT-HANDLING / -DELEGATION
   */

  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
    this.columnApi = gridReadyEvent.columnApi;

    this.initTableData();
  };

  initTableData() {
    const columns = this.initColumnDefinitions(this.columns.definitions, this.rowSelectionMode, this.rowModelType);
    this.gridApi.setColumnDefs(columns);
    this.setColumnState(this.columns.initialState);

    if (this.datasource) {
      this.gridApi.setDatasource(this.datasource);
    } else if (this.rowData) {
      this.gridApi.setRowData(this.rowData);
    }
    this.ready = true;

    this.initContextMenus();
  }

  onDisplayedColumnsChanged = (gridEvent: DisplayedColumnsChangedEvent) => {
    // squeeze columns to fit all on screen
    // gridEvent.api.sizeColumnsToFit();
    // get updated list of visible columns
    if (!this.scope || !this.ready) return;

    this.headerColumns = this.columnApi.getAllColumns()?.filter((column) => !column.getId().startsWith(PREFIX_INTERNAL_COLUMN)) || [];
    const newColumnState = this.parseColumnState(this.columnApi.getColumnState());
    this.uService.updateTableViewPreferences(this.scope, newColumnState);
    this.columnsChange.emit(newColumnState);
    this.cdr.markForCheck();
  };

  onColumnVisible = (columnEvent: ColumnVisibleEvent) => {
    if (!this.scope || !this.ready) return;
    if (this.refreshRowsOnColumnChange) {
      this.refreshResults();
    }
  };

  onSortChanged = (sortEvent: SortChangedEvent) => {
    if (!this.scope || !this.ready) return;
    const newColumnState = this.parseColumnState(sortEvent.columnApi.getColumnState());
    this.uService.updateTableViewPreferences(this.scope, newColumnState);
    this.sortChange.emit(newColumnState);
    this.cdr.markForCheck();
  };

  onFilterChanged = (event: FilterChangedEvent) => {
    console.log('filter changed');
    this.clearSelection();
  };

  onSelectionChanged = (event: SelectionChangedEvent) => {
    this.selectionChange.emit(this.gridApi && this.gridApi.getSelectedRows());
  };

  onRowClicked = (rowClickedEvent: RowClickedEvent) => {
    // rowClickedEvent.node.setSelected(true, true);
    this.rowClicked.emit(rowClickedEvent);
  };

  onRowDoubleClicked = (rowDblClickedEvent: RowDoubleClickedEvent) => {
    rowDblClickedEvent.node.setSelected(true, true);
    this.rowDoubleClicked.emit(rowDblClickedEvent);
  };

  onCellContextMenu = (ctxMenuEvent: CellContextMenuEvent) => {
    if (this.rowContextMenu) {
      console.debug('[table] row context menu detected');
      const { clientX, clientY } = (ctxMenuEvent.event as MouseEvent);
      const position = { x: clientX, y: clientY };

      const activeRow = ctxMenuEvent.data;
      let selectedRows = ctxMenuEvent.api.getSelectedRows();

      // manually select the row if it is not yet. Keeop the previously selected rows if the current one is part of them
      const withinSelection = this.rowSelectionMode === 'multiple' && (selectedRows.length > 1 && selectedRows.find((row) => row._id === activeRow._id) !== undefined);
      ctxMenuEvent.node.setSelected(true, !withinSelection);

      // update list of selected rows for parm binding for menu item
      selectedRows = ctxMenuEvent.api.getSelectedRows();
      const allRows: T[] = [];
      ctxMenuEvent.api?.forEachNode((node) => allRows.push(node.data));

      this.rowContextMenu.open({ active: activeRow, selected: selectedRows, all: allRows }, { x: clientX, y: clientY });
    } else {
      console.debug('[table] row context menu not found, ignore user action');
    }
  };

  navigateToNextCell = (params: NavigateToNextCellParams): CellPosition => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    let nextRowIndex;
    let retValue;
    switch (params.key) {
      case 'ArrowUp':
        nextRowIndex = previousCell.rowIndex - 1;
        if (nextRowIndex < 0) {
          retValue = null;
        } else {
          retValue = {
            rowIndex: nextRowIndex,
            column: previousCell.column,
          };
        }
        break;
      case 'ArrowDown': {
        nextRowIndex = previousCell.rowIndex + 1;
        const renderedRowCount = this.gridApi?.getModel().getRowCount();
        if (renderedRowCount && nextRowIndex >= renderedRowCount) {
          retValue = null;
        } else {
          retValue = {
            rowIndex: nextRowIndex,
            column: previousCell.column,
          };
        }
        break;
      }
      default:
        // throw 'this will never happen, navigation is up and down';
        retValue = null;
        break;
    }

    if (retValue !== null) {
      const rowNode = this.gridApi?.getDisplayedRowAtIndex(retValue.rowIndex);
      if (rowNode) {
        // selection is reset if multiple not allowed or user not pressing shift when navigating with arrows
        rowNode.setSelected(true, (this.rowSelectionMode !== 'multiple' || !params.event?.shiftKey));
        this.rowClicked.emit({
          api: this.getGridApi(),
          columnApi: this.getColumnApi(),
          type: 'KeyboardNavigation',
          node: rowNode,
          data: rowNode.data,
          rowIndex: retValue.rowIndex,
          rowPinned: null,
          context: null,
        });
      }
    }
    return retValue;
  };

  /**
   * Handle pressing escape-key: deselect all selected
   */
  @HostListener('document:keydown.escape')
  onKeydownHandler() {
    this.getGridApi().deselectAll();
  }

  toggleColumns(event: ColumnHeaderEvent) {
    this.getColumnApi().setColumnsVisible(event.columns, event.visibility);
  }

  resetColumns() {
    this.setColumnState(this.columns.defaultState);
  }

  private setColumnState(state: ColumnState[]) {
    let columns: ColumnState[];
    if (this.rowSelectionMode !== 'none' && this.rowSelectionType === 'checkbox') {
      columns = [{ colId: `${PREFIX_INTERNAL_COLUMN}CHECKBOX_SELECTION` }, ...state];
    } else {
      columns = [...state];
    }
    this.columnApi?.applyColumnState({
      applyOrder: true,
      state: columns.map((column) => ({ ...column, hide: false })),
      // state: this.defaultColumnState,
      defaultState: { hide: true, sort: null },
    });
    this.gridApi?.sizeColumnsToFit();
  }

  getGridApi(): GridApi {
    if (!this.gridApi) {
      throw new IllegalStateError('Grid-API is not available yet.');
    }
    return this.gridApi;
  }

  getColumnApi(): ColumnApi {
    if (!this.columnApi) {
      throw new IllegalStateError('Column-API is not available yet.');
    }
    return this.columnApi;
  }

  private parseColumnState(columnsState: ColumnState[]): ColumnState[] {
    return columnsState
      .filter((colState) => colState.hide === false && !colState.colId?.startsWith(PREFIX_INTERNAL_COLUMN))
      .map((colState) => {
        const newColState: ColumnState = { colId: colState.colId };
        if (colState.sort !== null) newColState.sort = colState.sort;
        if (colState.sortIndex !== null) newColState.sortIndex = colState.sortIndex;
        return newColState;
      }) || [];
  }

  private initContextMenus() {
    fromEvent(this.elementRef.nativeElement.querySelector('.ag-header-viewport'), 'contextmenu')
      .pipe(
        tap((event: MouseEvent) => {
          event.preventDefault();
          const { clientX, clientY } = event;
          this.headerContextMenuPosition = { x: `${clientX}px`, y: `${clientY}px` };
          this.headerContextMenuTrigger.openMenu();
        }),
        takeUntil(this.unsubscribe),
      ).subscribe();

    fromEvent(this.elementRef.nativeElement.querySelector('.ag-body-viewport'), 'contextmenu')
      .pipe(
        tap((event: MouseEvent) => {
          event.preventDefault();

          // binding of mat menu is done in the ag-grid specific event to recognize the row
        }),
        takeUntil(this.unsubscribe),
      ).subscribe();
  }

  toggleView() {
    if (this.datasource?.statsEnabled) {
      if (this.view === 'chart') {
        this.view = 'table';
      } else if (this.view === 'table') {
        this.view = 'chart';
        this.stats$ = this.datasource.getStats(
          null, /** @todo pass table filter to stats */
          { type: 'chords', field: 'metadata.client' },
        );
      }
    }
  }

  clearSelection() {
    // DJ-589 Tracks stay selected after filtering
    // user expects to clear selection when filtering
    this.getGridApi().deselectAll();
  }

  triggerMenuAction(menuItem: BoundContextMenuItem<T>) {
    const actionReturnValue = menuItem.boundAction();
    const action$ = actionReturnValue instanceof Observable ? actionReturnValue : of(actionReturnValue);

    action$.pipe(
      take(1),
      takeUntil(this.unsubscribe),
    ).subscribe((retValue) => {
      const eventData = { id: menuItem.id, data: retValue };
      console.debug('context menu event from table', eventData);
      this.contextMenuAction.emit(eventData);
    });
  }

  initColumnDefinitions(columns: ColDef[], selectionMode: RowSelectionMode, rowModelType: RowModelType): ColDef[] {
    if (!columns?.length) return [];

    const definitions: ColDef[] = [];
    if (this.hasCheckbox(selectionMode)) {
      definitions.push({
        headerName: '',
        // disable 'select all' header checkbox because of ux inconsistency due to infinite scroll loading
        field: `${PREFIX_INTERNAL_COLUMN}CHECKBOX_SELECTION`,
        headerCheckboxSelection: this.hasHeaderCheckbox(selectionMode, rowModelType),
        checkboxSelection: true,
        resizable: false,
        suppressMovable: true,
        lockPosition: true,
        lockVisible: true,
        suppressSizeToFit: true,
        width: ICON_CELL_WIDTH,
        filter: false,
        cellClass: 'hdis-table__selection-checkbox-cell',
        headerClass: 'hdis-table__header-empty-cell',
      });
    }

    return [...definitions, ...columns];
  }

  private hasCheckbox(selectionMode: RowSelectionMode) {
    return selectionMode !== 'none';
  }

  private hasHeaderCheckbox(selectionMode: RowSelectionMode, rowModelType: RowModelType) {
    return selectionMode !== 'none' && rowModelType !== 'infinite';
  }
}
