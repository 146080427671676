import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ComponentResizeEvent } from './component-resize.interfaces';

@Directive({
  selector: '[hdisResize]',
  standalone: true,
})
export class ComponentResizeDirective implements OnInit, OnDestroy {
  private observer: ResizeObserver;

  private prevDom?: DOMRectReadOnly;

  @Output()
  public readonly hdisResize;

  public constructor(
    private readonly element: ElementRef,
    private readonly zone: NgZone,
  ) {
    this.hdisResize = new EventEmitter<ComponentResizeEvent>();
    this.observer = new ResizeObserver((entries) => this.zone.run(() => this.observe(entries)));
  }

  public ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = new ComponentResizeEvent(domSize.contentRect, this.prevDom);
    this.prevDom = domSize.contentRect;
    this.hdisResize.emit(resizedEvent);
  }
}
